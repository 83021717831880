import React from "react"
import styled from "styled-components"
import axios from 'axios'
import { navigate, Link } from "gatsby"

import { BreadCrumb, FormControlLabel } from "../components/section"
import PhoneLineIcon from "../components/Icons/PhoneLineIcon"
import PaperPlanIcon from '../components/Icons/PaperPlanIcon'
import Layout from "../components/layout"
import SEO from "../components/seo"
import FormButton from "../components/button/FormButton"
import BreakpointUp from "../components/Media/BreakpointUp"
import BreakpointDown from "../components/Media/BreakpointDown"


const ContactGrid = styled.div`	
display:flex;
flex-wrap:wrap;
align-items:center;
margin:0 -15px; 
${BreakpointDown.lg`
    flex-direction:column-reverse;
`}

`
const AddressColumn = styled.div`    
width:100%;
padding:0 15px;
position:relative;
z-index:2;
margin-bottom:30px;
${BreakpointUp.lg`				
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
`}
& h2{
    font-size:20px;
    line-height:28px;
    color:#020304;
    font-family: 'Roboto', serif;
    margin-bottom:20px;
}
`
const FormColumn = styled.div`
width:100%;
padding:0 15px;
position:relative;
z-index:1;
margin-bottom:30px;
${BreakpointUp.lg`				
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
`}
& h1{
    margin-bottom: 25px;
    margin-top:15px;
    font-size:28px;
    line-height:38px;
    ${BreakpointUp.md`	
        font-size:34px;
        line-height:44px;
    `}
}

& p{       
    margin-bottom:40px;
}

`
const FormGrid = styled.div`	
display: grid;
align-items: center;
grid-column-gap: 30px;
grid-template-columns: repeat(1, 1fr);
${BreakpointUp.sm`
    grid-row-gap: 30px;
    grid-template-columns: repeat(2, 1fr);      
`}
`
const GridItem = styled.div`	
display:flex;
align-items:center;
margin:0 -10px 40px -10px;


`
const Icon = styled.div`	
display: flex;
align-items: center;
justify-content:center;
width:48px;
height:48px;
border-radius:50%;
background-color:#fff;
border:1px solid #ddd;
padding:0 10px;
`
const TextWrap = styled.div`	
display:inline-block;
verticle-align:middle;	
padding:0 10px;
& span{
    display:block;
}
& p{
    font-size:18px;
    font-weight:700;
    color:#020304;  
    margin-bottom:0;     
}
& a{
    font-size:18px;
    font-weight:700;
    color:#020304;
    text-decoration:underline;
    &:hover{
        text-decoration:none; 
        color:#020304;
    }
}
`
const SectionContact = styled.div`	
position:relative;
overflow:hidden;
padding:140px 0 30px 0;
${BreakpointUp.lg`			
    padding:140px 0 150px 0;
    // &:before{
    //     content: '';
    //     left: -500px;
    //     top: 0;
    //     bottom: 0;
    //     width: 1160px;
    //     height: 1160px;
    //     border-radius: 0 50% 50% 0;
    //     display: block;
    //     background-color:#F6F6F6;
    //     position: absolute;
    //     z-index: 1;
    // }
    // &:after{
    //     content: '';
    //     left: -350px;
    //     top: 0;
    //     bottom: 0;
    //     width: 1160px;
    //     height: 1160px;
    //     border-radius: 0 50% 50% 0;
    //     display: block;
    //     position: absolute;
    //     background-color: #FAFAFA;
    // }
`}	
`


class ContactUsPage extends React.Component {
constructor(props) {
    super(props)
    this.state = {
        first_name: '',
        last_name: '',
        email: '',
        phone_no: '',
        state: '',
        city: '',
        comment: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
}
componentDidMount() {
    if (typeof window !== 'undefined') 
    {
        const searchParams = new URLSearchParams(window.location.search);
        if(searchParams.get("utm_source")!='' && searchParams.get("utm_source")!=null)
        {
            sessionStorage.setItem('utm_source', searchParams.get("utm_source"));
            sessionStorage.setItem('utm_medium', searchParams.get("utm_medium"));
            sessionStorage.setItem('utm_campaign', searchParams.get("utm_campaign"));
            sessionStorage.setItem('utm_term', searchParams.get("utm_term"));
            sessionStorage.setItem('utm_content', searchParams.get("utm_content"));
        }
    }
}

handleChange(event) {
    const {id, value} = event.target
    this.setState({
        [id]: value
    })
}
handleSubmit(event) {
    let utm_source_val = 'website';
    let utm_medium_val = '';
    let utm_campaign_val = '';
    let utm_term_val = '';
    let utm_content_val = '';
    if(sessionStorage.getItem('utm_source')!='' && sessionStorage.getItem('utm_source')!=null) 
    {
        utm_source_val = sessionStorage.getItem('utm_source');
        utm_medium_val = sessionStorage.getItem('utm_medium');
        utm_campaign_val = sessionStorage.getItem('utm_campaign');
        utm_term_val = sessionStorage.getItem('utm_term');
        utm_content_val = sessionStorage.getItem('utm_content');
    }
    
    event.preventDefault()
    const { location } = this.props;
    const currentUrl = window.location.href;
    const data = {
        source: location.origin,
        form: "Contact Us",
        page: currentUrl,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_no: this.state.phone_no,
        state: this.state.state,
        city: this.state.city,
        comment: this.state.comment,
        utm_medium: utm_medium_val,
        utm_term: utm_term_val,
        utm_campaign: utm_campaign_val,
        utm_content: utm_content_val,
        lead_source: utm_source_val
    }
    axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: { Authorization: "Bearer " + process.env.FORM_CRM_TOKEN }
    }).then((res) => {
        if (typeof dataLayer !== 'undefined') {
			window.dataLayer.push({
				'event': 'form_submit',
				'formName': 'website_forms',
				'enhanced_conversion_data': {
					'email': this.state.email,
					'phone_number': this.state.phone_no
				}
			});
		}
        this.setState({ first_name: '', last_name: '', email: '', phone_no: '', state: '', city: '', comment: ''});
        navigate("/thanks");
        }).catch((error) => {
            alert(error)
        });
}
render() {
    const { location } = this.props;
    return(
        <Layout location={location}>
            <SEO title="Contact Us" />
            <SectionContact>
                <div className="container">
                    <BreadCrumb><Link to='/'>Home</Link>/<span>Contact Us</span></BreadCrumb>
                    <ContactGrid>            
                        <AddressColumn>
                            <h2>Contact Information</h2>
                            <GridItem>
                                <Icon><PhoneLineIcon /></Icon>
                                <TextWrap>
                                    <span>Phone Number</span>
                                    <p>
                                        <a href="tel:8776005980">
                                            (877) 600-5980
                                        </a>
                                    </p>
                                </TextWrap>
                            </GridItem>
                            <GridItem>
                                <Icon><PaperPlanIcon /></Icon>
                                <TextWrap>
                                    <span>Email ID</span>
                                    <a href="mailto:sales@premiermetalstructures.com">sales@premiermetalstructures.com</a>
                                </TextWrap>
                            </GridItem>
                        </AddressColumn>     
                        <FormColumn>
                            <h1>Get in touch</h1>
                            <form id="contactform" onSubmit={this.handleSubmit}>
                                <FormGrid>
                                    <div className="form-group-bordered">
                                        <input type="text" id="first_name" className="form-control" onChange={this.handleChange} required/>
                                        <FormControlLabel className={this.state.first_name!==''?"control-label active":"control-label"}>First Name</FormControlLabel>
                                    </div>
                                    <div className="form-group-bordered">
                                        <input type="text" id="last_name" className="form-control" onChange={this.handleChange} required/>
                                        <FormControlLabel className={this.state.last_name!==''?"control-label active":"control-label"}>Last Name</FormControlLabel>
                                    </div>
                                    <div className="form-group-bordered">
                                        <input type="email" id="email" className="form-control" onChange={this.handleChange} required/>
                                        <FormControlLabel className={this.state.email!==''?"control-label active":"control-label"}>Email Address</FormControlLabel>
                                    </div>
                                    <div className="form-group-bordered">
                                        <input type="tel" id="phone_no" className="form-control" onChange={this.handleChange} pattern="[0-9]{10}" required/>
                                        <FormControlLabel className={this.state.phone_no!==''?"control-label active":"control-label"}>Phone Number</FormControlLabel>
                                    </div>
                                    <div className="form-group-bordered">
                                        <select id="state" className="form-control"  onChange={this.handleChange}>
                                            <option value="" selected="selected">State</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">District Of Columbia</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NY">New York</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                    </div>                           
                                </FormGrid>
                                <div className="form-group-bordered">
                                    <textarea id="comment" className="form-control" onChange={this.handleChange} required />
                                    <FormControlLabel className={this.state.comment!==''?"control-label active":"control-label"}>Write your comment here…</FormControlLabel>
                                </div>
                                <div className="form-action">
                                    <FormButton text="Submit" />
                                </div>          
                            </form> 
                        </FormColumn>           
                    </ContactGrid>
                </div>
            </SectionContact>    
        </Layout>
    )
}
}

export default ContactUsPage
